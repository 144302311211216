.black {
    color: #3f3f3f
}

.red {
    color: #cc0000
}

.green {
    color: #00aa00
}

.option-item {
    display: inline-block;
    width: 100%;
    height: 50px;
}
