.question-container {
    width: 100%;
    height: 100vh;
    margin: 0;
    display: flex;
    padding-top: 300px;
    flex-direction: column;
    align-items: center;
}

.next-button {
    margin-top: 60px;
}
