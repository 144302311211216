body {
    margin: 0;
    padding: 0 16px 0 16px;
}

p, span, button {
    font-size: 24px;
}

button {
    border-style: solid;
    border-color: black;
    padding: 8px;
}
